import Paginate from 'vuejs-paginate'

export default {
    props: ['isCertificateStart', 'individual', 'contentStyle', 'leaderBoardSetting'],
    components: {
        Paginate
    },
    data: function() {
        return {
            countyOptions: ['全國'],

            selectedOption: {
                county: '全國',
                grade: {
                    value: '0',
                    text: '請選擇年級',
                }
            },

            chartsList: [],
            personalResults: [],

            perPage: 20,
            pageCount: 0,
            totalCount: 0,
            selectedPage: 1,

            isSetDataReady: {
                getCountyOptions: false,  // 取得臺灣縣市選項列表
                initializeCharts: false,  // 篩選條件下的排行榜
                getChartsList: false,  // 取得排行榜
            },
            isPostingApi: {
                downloadReport: false,  // 下載報表
            },
            isCountyOn: false, // 顯示地區
            isGradeOn: false, // 顯示年級
            columnInfo: []     // 排行榜表格欄位資訊
        }
    },
    watch: {
        userInfo(newValue) {
            if (this.$_.isEmpty(newValue)) {
                // 未登入使用者需初始個人得分資訊
                this.personalResults = [];
            } else {
                this.initializeCharts();
            }
        },
        selectedOptStr() {
            this.initializeCharts();
        },
        'selectedOption.grade.value'(newValue) {
            if (newValue) {
                this.initializeCharts();
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        countyDistrict() {
            return this.$store.state.common.countyDistrict;
        },
        selectedOptStr() {
            return Object.values(this.selectedOption).join('|');
        },
        gradeOptions() {
            let list = [
                {
                    value: '0',
                    text: '請選擇年級',
                },
                {
                    value: 1,
                    text: '一年級',
                },
                {
                    value: 2,
                    text: '二年級',
                },
                {
                    value: 3,
                    text: '三年級',
                },
                {
                    value: 4,
                    text: '四年級',
                },
                {
                    value: 5,
                    text: '五年級',
                },
                {
                    value: 6,
                    text: '六年級',
                },
                {
                    value: 7,
                    text: '七年級',
                },
                {
                    value: 8,
                    text: '八年級',
                },
                {
                    value: 9,
                    text: '九年級',
                },
                {
                    value: 10,
                    text: '十年級',
                },
                {
                    value: 11,
                    text: '十一年級',
                },
                {
                    value: 12,
                    text: '十二年級',
                },
            ];

            let result = [];
            if (this.individual.gradeLow != 0 &&
                this.individual.gradeHeigh != 0)
            for (let el of list) {
                if (el.value == 0 ||
                    el.value >= this.individual.gradeLow &&
                    el.value <= this.individual.gradeHeigh) {
                        result.push(el);
                }
            }
            else {
                result = list;
            }

            return result;
        },
    },
    created: function() {
        this.getCountyOptions();
        this.initializeCharts();
        this.getTableColumns();
    },
    methods: {
        async getCountyOptions() {
            await this.$store.dispatch('common/getCountyDistrict');
            let tempCounties = this.leaderBoardSetting.counties.length > 0 ? this.leaderBoardSetting.counties : this.$_.cloneDeep(this.countyDistrict.counties);
            this.countyOptions = this.countyOptions.concat(tempCounties);
            this.isSetDataReady.getCountyOptions = true;
        },

        async initializeCharts() {
            this.selectedPage = 1;
            this.isSetDataReady.initializeCharts = false;
            await this.getChartsPages();
            await this.getChartsList();
            this.isSetDataReady.initializeCharts = true;
        },
        getChartsPages() {
            return new Promise((resolve, reject) => {
                let params = {
                    alias: this.$route.params.alias,
                    isGroup: 0,
                    itemPerPage: this.perPage
                }

                // 篩選縣市
                if (this.selectedOption.county !== '全國') {
                    params.county = this.selectedOption.county
                }

                // 篩選年級
                if (this.selectedOption.grade.value !== '0') {
                    params.grade = this.selectedOption.grade.value
                }

                this.$httpRequest.get('/api/event/get_leaderboard_pages', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            this.pageCount = result.total_page;
                            this.totalCount = result.total_count;

                            resolve();
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getChartsList() {
            return new Promise((resolve, reject) => {
                // 換頁時置頂卷軸, 方便使用者查看排行榜
                if (this.isSetDataReady.initializeCharts) {
                    window.scroll(0, 0);
                }

                this.isSetDataReady.getChartsList = false;
                this.chartsList = [];

                let params = {
                    alias: this.$route.params.alias,
                    page: this.selectedPage,
                    itemPerPage: this.perPage
                }

                // 篩選縣市
                if (this.selectedOption.county !== '全國') {
                    params.county = this.selectedOption.county
                }

                // 篩選年級
                if (this.selectedOption.grade.value !== '0') {
                    params.grade = this.selectedOption.grade.value
                }

                this.$httpRequest.get('/api/event/get_personal_rank', params)
                    .then(async (response) => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                // 若選擇頁數無結果時, 則需重新取得總頁數, 再取最後一頁列表資料
                                if (this.pageCount && !result.pages.length) {
                                    await this.getChartsPages();
                                    this.selectedPage = this.pageCount;
                                    this.getChartsList(this.selectedPage);
                                    return;
                                }

                                // 排行榜資訊
                                for (let el of result.pages) {
                                    this.chartsList.push({
                                        rank: el.rank,
                                        name: el.name,
                                        score: el.score,
                                        county: el.county,
                                        school: el.school,
                                        class: el.class,
                                        grade: el.grade,
                                        account: el.account,
                                        isCertificate: el.is_certificate
                                    });
                                }

                                // 個人排名資訊
                                if (!this.personalResults.length) {
                                    for (let el of result.personal) {
                                        this.personalResults.push({
                                            rank: el.rank,
                                            name: el.name,
                                            score: el.score,
                                            county: el.county,
                                            school: el.school,
                                            class: el.class,
                                            account: el.account,
                                            isCertificate: el.is_certificate
                                        });
                                    }
                                }

                                this.isSetDataReady.getChartsList = true;

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        downloadReport() {
            let params = {
                alias: this.$route.params.alias
            }

            this.isPostingApi.downloadReport = true;

            this.$httpRequest.post('/api/event/get_personal_report', params)
                .then(async (response) => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            try {
                                await this.$util.downloadFile(result.url, result.name);
                                this.isPostingApi.downloadReport = false;
                            } catch (error) {
                                this.isPostingApi.downloadReport = false;
                                console.error('Catched Error:', error);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.downloadReport = false;
                    console.error('Catched Error:', error);
                });
        },
        downloadCertificate(personal) {
            window.location.href = process.env.VUE_APP_ROOT_API +
            '/api/event/personal_certificate?eventId=' + this.$route.params.alias +
            '&county=' + personal.county +
            '&class=' + personal.class +
            '&grade=' + personal.grade +
            '&name=' + personal.name +
            '&rank=' + personal.rank +
            '&school=' + personal.school +
            '&score=' + personal.score;
        },
        getTableColumns() {
            // 欄位全開時的寬度比例，預留10給獎狀，所以加起來只能90
            const columnWidths = {
                county: 10,
                school: 15,
                grade: 10,
                class: 10,
                name: 10,
                account: 15,
                score: 10,
                allRank: 10
            };
            const columnNames = {
                county: '縣市',
                school: '學校/單位',
                grade: '年級',
                class: '班級',
                name: '姓名',
                account: '帳號',
                score: '獲得分數',
                allRank: '總排名'
            };            
            var cols = 0;                // 排行榜有幾個欄位
            var remainderSize = 100;    // 剩餘寬度
            this.leaderBoardSetting.personal.forEach(element => {
                for (let key in element) {
                    if (columnWidths[key] == 'undefined') {
                        console.error('key ' + key + ' undefined.');
                        continue;
                    }

                    if (element[key]) {
                        const width = columnWidths[key];
                        if (key == 'allRank') {     // api key name跟chartsList不一致，特別處理
                            this.columnInfo.push({ 'key': 'rank', 'name': columnNames[key], 'width': width});
                        } else {
                            this.columnInfo.push({ 'key': key, 'name': columnNames[key], 'width': width});
                        }
                        remainderSize -= width;
                        cols++;

                        if (key == 'grade') {
                            this.isGradeOn = true;
                        } else if (key == 'county') {
                            this.isCountyOn = true;
                        }
                    }
                }
            });
            // 獎狀固定在最後
            if (this.isCertificateStart && this.userInfo.permission == 'admin') {
                this.columnInfo.push({ 'key': 'isCertificate', 'name': '獎狀', 'width': 10});
                remainderSize -= 10;
                cols++;
            }
            // 目前剩餘多少寬度，平分給個欄位
            const addWidth = remainderSize / cols;
            this.columnInfo = this.columnInfo.map(col => {
                return { ...col, width: col.width + addWidth };
            });
        }
    }
}
