export default class AudioRecorder {
    constructor(data) {
        this.sampleRate = data.sampleRate;
        this.channelCount = data.channelCount;
    }

    #errMsg = {
        '-1': '系統繁忙中，請稍後再試',
        '0': '瀏覽器未使用https協定請求或不支援存取多媒體數據',
        '1': '瀏覽器尚未允許麥克風輸入權限',
        '2': '瀏覽器找不到麥克風裝置',
        '3': '瀏覽器不支援存取多媒體數據'
    }
    #errLog = {
        '-1': `Exception error`,
        '0': `"mediaDevices API" or "getUserMedia" is not supported`,
        '1': `A "NotAllowedError" has occured`,
        '2': `A "NotFoundError" has occured`,
        '3': `The browser doesn't support the specified sampleRate`
    }

    #recorder = {
        capturedStream: null,  /* MediaStream */
        audioCtx: null,  /* AudioContext */
        sourceNode: null,  /* MediaStreamAudioSourceNode */
        gainNode: null,  /* GainNode */
        analyserNode: null,  /* AnalyserNode */
        processNode: null,  /* AudioWorkletNode | ScriptProcessorNode */
        audioBuffers: [],
        isRecording: false,
        averageDecibel: 0
    }

    /**
     * 初始瀏覽器錄音設定
     */
    init = async function () {
        // 不支援存取多媒體數據 (不支援 WebRTC)
        if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
            return Promise.reject(this.#getRejectedMsg('0', 'getUserMedia'));
        }

        try {
            // 依據設定的通道數量初始 buffers 資料結構
            this.#recorder.audioBuffers = [];
            for (let channel = 0; channel < this.channelCount; channel++) {
                this.#recorder.audioBuffers[channel] = [];
            }

            /** iOS Safari 不能重複呼叫 `getUserMedia()`, 否則聲音流會被重置而沒有聲音 */

            // 取得聲音流
            this.#recorder.capturedStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // 取得輸入裝置名稱
            const tracks = this.#recorder.capturedStream.getTracks();
            const label = tracks[0].label;

            return Promise.resolve(label);
        } catch (error) {
            let errCode = this.#handleGetUserMediaExceptions(error.name);
            return Promise.reject(this.#getRejectedMsg(errCode, 'getUserMedia', error));
        }
    }

    /**
     * 開始錄音
     */
    start = async function () {
        try {
            /* AudioContext: InputNodes(Source) → ProcessingNodes → OutputNodes(Destination) */

            // 定義取樣頻率並建立由多個聲音節點 (AudioNode) 組成的聲音容器
            const AudioContext = window.AudioContext || window.webkitAudioContext;
            const audioCtx = new AudioContext({ sampleRate: this.sampleRate });
            this.#recorder.audioCtx = audioCtx;

            // 將聲音流轉為音源節點, 取得音訊來源
            this.#recorder.sourceNode = audioCtx.createMediaStreamSource(this.#recorder.capturedStream);

            // 建立增益節點, 用於控制音量
            this.#recorder.gainNode = audioCtx.createGain();
            this.#recorder.gainNode.gain.value = 3;

            // 建立音頻分析節點, 取得音頻資訊 (透過 FFT 對音訊進行頻域與時域上的分析)
            this.#recorder.analyserNode = audioCtx.createAnalyser();

            /**
             * 取得音訊資料的方法, 目前使用 createScriptProcessor 建立 ScriptProcessorNode, 但此方法在 MDN 文件已註明廢棄[1], 未來將全面使用 AudioWorkletNode, 但測試使用 AudioWorkletNode 的結果發現, 基於效能考量, Safari 瀏覽器會有音訊資料不完全的現象, 因此目前暫時仍先使用 ScriptProcessorNode, 待未來 Safari 不會有問題時, 再改回 AudioWorkletNode
             * [1] https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createScriptProcessor
             */

            // 建立音訊處理節點, 對聲音進行取樣並取得音訊資料 (連續訊號 → 離散訊號)
            // 緩衝區是用於保留音訊資料, 當緩衝區滿了即會觸發 `onaudioprocess()` 事件, 以取得一段音訊資料
            const bufferSize = 4096;
            this.#recorder.processNode = audioCtx.createScriptProcessor(bufferSize, this.channelCount, this.channelCount);

            // 接收音訊資料流
            this.#recorder.processNode.onaudioprocess = e => {
                if (!this.#recorder.isRecording) {
                    return;
                }

                // 存取音訊資料
                let inputBuffer = e.inputBuffer;

                for (let channel = 0; channel < this.channelCount; channel++) {
                    this.#recorder.audioBuffers[channel].push(inputBuffer.getChannelData(channel).slice(0));
                }

                // 計算平均分貝
                let bufferLength = this.#recorder.analyserNode.frequencyBinCount;
                let fftBuffers = new Uint8Array(bufferLength);
                this.#recorder.analyserNode.getByteFrequencyData(fftBuffers);  // 每字節數值範圍 0~255 整數, 每項皆代表特定頻率的分貝值

                let values = 0;
                for (let i = 0; i < fftBuffers.length; i++) {
                    values += fftBuffers[i];
                }
                this.#recorder.averageDecibel = values / fftBuffers.length;
            }

            // 連接各節點
            // 將音源透過增益節點放大音量, 再取得平均分貝
            this.#recorder.sourceNode
                .connect(this.#recorder.gainNode)
                .connect(this.#recorder.analyserNode);
            // 將音源經由音訊處理節點取得音訊資料, 再輸出至目標節點 (通常是揚聲器)
            this.#recorder.sourceNode
                .connect(this.#recorder.processNode)
                .connect(audioCtx.destination);

            this.#recorder.isRecording = true;
        } catch (error) {
            let errCode = this.#handleAudioContextExceptions(error.name);
            return Promise.reject(this.#getRejectedMsg(errCode, 'AudioContext', error));
        }
    }

    /**
     * 暫停錄音
     */
    pause = function () {
        this.#recorder.isRecording = false;
    }

    /**
     * 恢復錄音
     */
    resume = async function () {
        try {
            const isTouchDevice = navigator.maxTouchPoints > 0;
            if (!isTouchDevice) {  // 非觸控裝置才再次檢查麥克風裝置是否正常
                await navigator.mediaDevices.getUserMedia({ audio: true });
            }

            this.#recorder.isRecording = true;
        } catch (error) {
            let errCode = this.#handleGetUserMediaExceptions(error.name);
            return Promise.reject(this.#getRejectedMsg(errCode, 'getUserMedia', error));
        }
    }

    /**
     * 停止錄音
     */
    stop = function () {
        this.#recorder.isRecording = false;

        // 關閉麥克風
        if (this.#recorder.capturedStream) {
            const tracks = this.#recorder.capturedStream.getTracks();
            // tracks[0] -> MediaStreamTrack
            for (const track of tracks) {
                track.stop();
            }
        }

        if (this.#recorder.audioCtx) {
            // 斷開節點連接
            this.#recorder.sourceNode.disconnect();
            this.#recorder.gainNode.disconnect();
            this.#recorder.analyserNode.disconnect();
            this.#recorder.processNode.disconnect();

            // 關閉 AudioContext 以釋放 CPU
            this.#recorder.audioCtx.close();
        }

        this.#resetProperties();
    }

    /**
     * 取得錄音檔案 (.wav)
     * @returns {File} 錄音檔案
     */
    getFile = function () {
        // 合併為單個 Float32Array
        let mergedBuffers = [];
        for (let channel = 0; channel < this.channelCount; channel++) {
            mergedBuffers.push(this.#mergeBuffers(this.#recorder.audioBuffers[channel]));
        }

        // 如果設定雙聲道, wav 格式儲存時需將左右聲道的資料交叉合併
        let audioData = null;
        if (this.channelCount === 2) {
            audioData = this.#interleaveBuffers(mergedBuffers[0], mergedBuffers[1]);
        } else {
            audioData = mergedBuffers[0];
        }

        // 建立 wav 檔案
        let arrayBuffer = this.#createWavFile(audioData);

        let mimeType = 'audio/wav';
        let audioBlob = new Blob([new Uint8Array(arrayBuffer)], { type: mimeType });

        let rand = Math.floor(Math.random() * 1000000);
        let audioFile = new File([audioBlob], `voice_${rand}.wav`, { type: mimeType });

        return audioFile;
    }

    /**
     * 取得輸入音量的平均分貝數
     * @returns {Float} 分貝數
     */
    getDecibel = function () {
        return this.#recorder.averageDecibel;
    }

    /**
     * 重設屬性值
     * @private
     */
    #resetProperties = function () {
        this.#recorder.capturedStream = null;
        this.#recorder.audioCtx = null;
        this.#recorder.sourceNode = null;
        this.#recorder.analyserNode = null;
        this.#recorder.processNode = null;
        this.#recorder.averageDecibel = 0;
    }

    /**
     * 合併音訊資料
     * @private
     * @param {Array} bufferList 數個 Float32Array 組成的陣列
     * @returns {ArrayBuffer} 合併後的單個 Float32Array 資料
     */
    #mergeBuffers = function (bufferList) {
        /* bufferList: [Float32Array, Float32Array, Float32Array, ...] */
        /* result: Float32Array */

        let length = bufferList.length * bufferList[0].length;
        let result = new Float32Array(length);
        let offset = 0;

        for (let i = 0; i < bufferList.length; i++) {
            result.set(bufferList[i], offset);
            offset += bufferList[i].length;
        }

        return result;
    }

    /**
     * 交叉左右聲道音訊資料
     * @private
     * @param {ArrayBuffer} left 左聲道音訊資料
     * @param {ArrayBuffer} right 右聲道音訊資料
     * @returns {ArrayBuffer} 交叉後的音訊資料
     */
    #interleaveBuffers = function (left, right) {
        let length = left.length + right.length;
        let result = new Float32Array(length);

        for (let i = 0; i < left.length; i++) {
            let k = i * 2;
            result[k] = left[i];
            result[k+1] = right[i];
        }

        return result;
    }

    /**
     * 建立 wav 檔案
     * @private
     * @param {ArrayBuffer} samples 音訊資料
     * @returns {ArrayBuffer} wav格式的音訊資料
     */
    #createWavFile = function (samples) {
        const headSize = 44;

        let buffer = new ArrayBuffer(headSize + samples.length * 2);
        let view = new DataView(buffer);

        function writeString(view, offset, string) {
            for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        }

        // 寫入 wav 頭部資訊
        const sampleBits = 16;  // 每樣本位元數 (取樣深度)
        let blockAlign = this.channelCount * (sampleBits / 8);

        /* ChunkID, 4 bytes */
        writeString(view, 0, 'RIFF');
        /* ChunkSize, 4 bytes */
        view.setUint32(4, 36 + samples.length * blockAlign, true);
        /* Format, 4 bytes */
        writeString(view, 8, 'WAVE');
        /* Subchunk1ID, 4 bytes */
        writeString(view, 12, 'fmt ');
        /* Subchunk1Size, 4 bytes */
        view.setUint32(16, 16, true);
        /* AudioFormat, 2 bytes */
        view.setUint16(20, 1, true);
        /* NumChannels, 2 bytes */
        view.setUint16(22, this.channelCount, true);
        /* SampleRate, 4 bytes */
        view.setUint32(24, this.sampleRate, true);
        /* ByteRate, 4 bytes */
        view.setUint32(28, this.sampleRate * blockAlign, true);
        /* BlockAlign, 2 bytes */
        view.setUint16(32, blockAlign, true);
        /* BitsPerSample, 2 bytes */
        view.setUint16(34, sampleBits, true);
        /* Subchunk2ID, 4 bytes */
        writeString(view, 36, 'data');
        /* Subchunk2Size, 4 bytes */
        view.setUint32(40, samples.length * blockAlign, true);

        /* data */
        // 寫入 PCM 資料 (離散訊號 → 數位訊號)
        // 16位二進制, 範圍 [-32768, +32767] => [0x8000, 0x7FFF]
        let offset = 44;

        for (let i = 0; i < samples.length; i++, offset += 2) {
            // 資料取樣點的範圍是 [-1. 1], 計算每個取樣點的比例係數
            let s = Math.max(-1, Math.min(1, samples[i]));
            // 依據比例係數將32位浮點數轉換為16位正整數
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }

        return buffer;
    }

    /**
     * 處理 `getUserMedia()` 捕獲的例外錯誤
     * @private
     * @param {String} errName 錯誤名稱
     * @returns {String} 錯誤代碼
     */
    #handleGetUserMediaExceptions = function (errName) {
        switch (errName) {
            case 'NotAllowedError':
                return '1';
            case 'NotFoundError':
                return '2';
            default:
                return '-1';
        }
    }

    /**
     * 處理 `AudioContext` 捕獲的例外錯誤
     * @private
     * @param {String} errName 錯誤名稱
     * @returns {String} 錯誤代碼
     */
    #handleAudioContextExceptions = function (errName) {
        switch (errName) {
            /**
             * "NotSupportedError": The specified sampleRate isn't supported by the context.
             * Thrown by AudioContext.createMediaStreamSource()
             */
            case 'NotSupportedError':
                return '3';
            default:
                return '-1';
        }
    }

    /**
     * 取得錯誤返回內容
     * @private
     * @param {String} errCode 錯誤代碼
     * @param {String} errApiName 發生錯誤的API名稱
     * @param {Object} error 錯誤內容
     * @returns {String} 包含代碼與訊息的回應內容
     */
    #getRejectedMsg = function (errCode, errApiName, error = '') {
        let outputLog = `Audio Recorder Error: ${errApiName}: ${this.#errLog[errCode]}`;
        if (errCode === '-1' && error) {
            outputLog = `${outputLog}: ${error}`;
        }
        console.error(outputLog);

        let response = { code: errCode, msg: this.#errMsg[errCode] };

        return JSON.stringify(response);
    }
}
