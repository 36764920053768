import { render, staticRenderFns } from "./charts_team.vue?vue&type=template&id=afe83fbe&scoped=true&"
import script from "@/assets/js/components/event/charts_team.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/event/charts_team.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/event/charts.scss?vue&type=style&index=0&id=afe83fbe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afe83fbe",
  null
  
)

export default component.exports